/* storybook-check-ignore */
import { Box, Flex, Text } from '@opendoor/bricks/core';

import SharingLinksButton from 'components/exclusives/SharingLinksButton';
import VisuallyHidden from 'components/exclusives/VisuallyHidden';
import { AccountProps } from 'components/shared/AccountConnector';

import { ListingWithComputedProperties } from 'declarations/exclusives/listing';

import {
  getIsAwaitingSellerCommitment,
  getIsInContract,
  getListingAddressHumanReadable,
  getListingPriceCents,
  getOriginalListingPriceCents,
  shouldShowPrelimOfferPriceRange,
} from 'helpers/exclusives/listing';

import FavoriteButton from '../../FavoriteButton';

export interface GalleryCardBodyProps {
  account?: AccountProps;
  isListingOnWatchlist?: boolean;
  listing: ListingWithComputedProperties;
  onAddedToWatchlist?: (listing: ListingWithComputedProperties) => void;
  onNotifiableEventError?: (message?: string) => void;
  onNotifiableEventSuccess?: (message?: string) => void;
  onRemovedFromWatchlist?: (listing: ListingWithComputedProperties) => void;
  source?: 'gallery' | 'pdp' | 'saved-homes';
}

export function GalleryCardBody({
  account,
  isListingOnWatchlist,
  listing,
  onAddedToWatchlist,
  onNotifiableEventError,
  onNotifiableEventSuccess,
  onRemovedFromWatchlist,
  source = 'gallery',
}: GalleryCardBodyProps) {
  const { bedrooms, bathrooms, square_footage } = listing;
  const userEmail = account?.email ? account.email : account?.human?.get('email');
  const isAwaitingSellerCommitment = getIsAwaitingSellerCommitment(listing);
  const isInContract = getIsInContract(listing);
  const address = getListingAddressHumanReadable(listing);
  const priceCents = getListingPriceCents(listing);
  const price = !!priceCents && `$${(priceCents / 100).toLocaleString()}`;
  const originalPriceCents = getOriginalListingPriceCents(listing);
  const originalPrice = !!originalPriceCents && `$${(originalPriceCents / 100).toLocaleString()}`;
  const [numberAndStreet, ...restOfAddress] = address.split(',').map((it) => it.trim());

  const showPrelimOfferPriceRange = shouldShowPrelimOfferPriceRange(listing);
  const prelimOfferRange = listing.exclusive_listing_info.prelim_offer_price_range;

  return (
    <Flex flexDirection="column" gap={2}>
      <Flex justifyContent="space-between" mb={-3}>
        <Text
          css={{ fontWeight: 500 }}
          mt={2}
          fontSize="s2"
          lineHeight="s2"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {(!isAwaitingSellerCommitment || showPrelimOfferPriceRange) &&
            (priceCents !== 0 && !isInContract ? (
              <>
                <VisuallyHidden>Price: </VisuallyHidden>
                {price}
                {!!originalPriceCents && (
                  <Text
                    as="span"
                    color="neutrals70"
                    ml={3}
                    fontSize="s1"
                    lineHeight="s1"
                    textDecorationLine="line-through"
                  >
                    <VisuallyHidden>Original price: </VisuallyHidden>
                    {originalPrice}
                  </Text>
                )}
              </>
            ) : showPrelimOfferPriceRange && prelimOfferRange && !isInContract ? (
              `$${numberFormatter.format(
                prelimOfferRange.lower_bound_cents / 100,
              )} - $${numberFormatter.format(prelimOfferRange.upper_bound_cents / 100)}`
            ) : (
              numberAndStreet
            ))}
        </Text>

        <Flex>
          <SharingLinksButton
            backgroundColor="transparent"
            border={0}
            boxShadow="none"
            height={40}
            listing={listing}
            onSharingError={onNotifiableEventError}
            onSharingSuccess={onNotifiableEventSuccess}
            source={source}
            width={40}
          />
          <FavoriteButton
            accountEmail={userEmail}
            addAnalyticsLabel="add"
            analyticsAdditionalContextualData={{ button_type: 'favorite', source: 'gallery' }}
            backgroundColor="transparent"
            boxShadow="none"
            height={40}
            listing={listing}
            isListingOnWatchlist={isListingOnWatchlist}
            onAddedToWatchlist={() => onAddedToWatchlist?.(listing)}
            onRemovedFromWatchlist={() => onRemovedFromWatchlist?.(listing)}
            width={40}
            source={source}
          />
        </Flex>
      </Flex>

      <Box>
        <Text
          color="neutrals100"
          fontSize="s0"
          lineHeight="s0"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {bedrooms} bd · {bathrooms} ba · {square_footage.toLocaleString()} sqft
        </Text>
        <Text
          color="neutrals90"
          fontSize="s0"
          lineHeight="s0"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {price || showPrelimOfferPriceRange ? address : restOfAddress.join(', ')}
        </Text>
      </Box>
    </Flex>
  );
}

const numberFormatter = Intl.NumberFormat('en', { notation: 'compact' });
