import { Flex, Text } from '@opendoor/bricks/core';
import { DateTime } from 'luxon';

import { Badge, BadgeProps } from './Badge';

export type OpenHouseBadgeProps = Omit<BadgeProps, 'prefix' | 'text'> & {
  endTime: string;
  startTime: string;
  timezone?: string;
};

export const OpenHouseBadge = ({
  endTime: _endTime,
  startTime: _startTime,
  timezone = CT_TIMEZONE,
}: OpenHouseBadgeProps) => {
  const startTime = DateTime.fromISO(_startTime);
  const endTime = DateTime.fromISO(_endTime);

  const today = DateTime.now().endOf('day');
  const thisWeekend = today.plus({ days: 6 });
  const hasPassed = startTime < thisWeekend;

  const formattedStartTime = (
    hasPassed
      ? startTime.setZone(timezone).toFormat('EEE h:mm')
      : startTime.setZone(timezone).toFormat('EEE MM/d, h:mm')
  ).replace(':00', '');

  const formattedEndTime = endTime.setZone(timezone).toFormat('h:mm a').replace(':00', '');

  return (
    <Badge
      backgroundColor="neutrals0"
      color="neutrals100"
      paddingLeft={2}
      prefix={
        <Flex backgroundColor="neutrals100" borderRadius="squared" height={16}>
          <Text
            color="neutrals0"
            css={{
              /**
               * We set the line-height to `17px` here to make sure
               * that the text is aligned vertically.
               */
              lineHeight: '17px',
            }}
            fontSize="s000"
            fontWeight="semibold"
            px={2}
            textTransform="uppercase"
          >
            Open
          </Text>
        </Flex>
      }
      text={`${formattedStartTime}-${formattedEndTime}`}
    />
  );
};

const CT_TIMEZONE = 'America/Chicago';
