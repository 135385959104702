import StarFourWay from '../icons/StarFourWay';
import { Badge, BadgeProps } from './Badge';

export type ExclusiveBadgeProps = Omit<BadgeProps, 'text'> & Partial<Pick<BadgeProps, 'text'>>;

export const ExclusiveBadge = ({ text = 'Exclusive', ...restOfProps }: ExclusiveBadgeProps) => {
  return (
    <Badge
      backgroundColor="brand50"
      backgroundImage="linear-gradient(56.35deg, var(--colors-brand50) 36.59%, #0D76D6 103.23%)"
      color="neutrals0"
      prefix={<StarFourWay />}
      text={text}
      {...restOfProps}
    />
  );
};
