import { memo } from 'react';

import { GalleryCardBody, GalleryCardBodyProps } from './GalleryCardBody';
import { GalleryCardPhotoSection, GalleryCardPhotoSectionProps } from './GalleryCardPhotoSection';
import { GalleryCardWrapper } from './GalleryCardWrapper';

export interface GalleryCardv2Props extends GalleryCardPhotoSectionProps, GalleryCardBodyProps {}

export const _GalleryCardv2 = ({
  account,
  isListingOnWatchlist,
  listing,
  onAddedToWatchlist,
  onNotifiableEventError,
  onNotifiableEventSuccess,
  onRemovedFromWatchlist,
  showOverlay,
  source,
  thumbnailUrl,
}: GalleryCardv2Props) => {
  return (
    <GalleryCardWrapper>
      <GalleryCardPhotoSection
        listing={listing}
        showOverlay={showOverlay}
        thumbnailUrl={thumbnailUrl}
      />
      <GalleryCardBody
        account={account}
        isListingOnWatchlist={isListingOnWatchlist}
        listing={listing}
        onAddedToWatchlist={onAddedToWatchlist}
        onNotifiableEventError={onNotifiableEventError}
        onNotifiableEventSuccess={onNotifiableEventSuccess}
        onRemovedFromWatchlist={onRemovedFromWatchlist}
        source={source}
      />
    </GalleryCardWrapper>
  );
};

export const GalleryCardv2 = memo(_GalleryCardv2);
