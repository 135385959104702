/* storybook-check-ignore */
import { Flex } from '@opendoor/bricks/core';
import { useReducedMotion } from 'framer-motion';

import { AccountProps } from 'components/shared/AccountConnector';

import { ListingWithComputedProperties } from 'declarations/exclusives/listing';

export type GalleryCardWrapperProps = {
  account?: AccountProps;
  isListingOnWatchlist?: boolean;
  listing: ListingWithComputedProperties;
  onAddedToWatchlist?: (listing: ListingWithComputedProperties) => void;
  onNotifiableEventError?: (message?: string) => void;
  onNotifiableEventSuccess?: (message?: string) => void;
  onRemovedFromWatchlist?: (listing: ListingWithComputedProperties) => void;
  source?: 'gallery' | 'pdp' | 'saved-homes';
  thumbnailUrl: string;
};

export function GalleryCardWrapper({ children }: { children: React.ReactNode }) {
  const shouldReduceMotion = useReducedMotion();

  return (
    <Flex
      _active={{
        backgroundColor: 'neutrals20',
        boxShadow: '0 0 0 8px var(--colors-neutrals20)',
      }}
      _hover={{
        img: { transform: shouldReduceMotion ? undefined : 'scale(1.1)' },
      }}
      boxShadow="0 0 0 8px transparent"
      css={{ borderRadius: 12 }}
      cursor="pointer"
      flexDirection="column"
      gap={3}
      position="relative"
      transitionDuration="250ms"
      transitionProperty="background-color, box-shadow"
      transitionTimingFunction="ease-in-out"
    >
      {children}
    </Flex>
  );
}
