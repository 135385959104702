/* storybook-check-ignore */
import { useMemo } from 'react';

import { Box, Flex, Image, Text } from '@opendoor/bricks/core';

import { AvailableSoonBadge } from 'components/exclusives/badges/AvailableSoonBadge';
import { ExclusiveBadge } from 'components/exclusives/badges/ExclusiveBadge';
import { OpenHouseBadge } from 'components/exclusives/badges/OpenHouseBadge';
import { VirtualTourBadge } from 'components/exclusives/badges/VirtualTourBadge';
import { DEFAULT_LISTING_IMAGE, ListingStates } from 'components/exclusives/constants/constants';

import { ListingWithComputedProperties } from 'declarations/exclusives/listing';

import {
  getEarliestOpenHouse,
  remainingDaysRoundedToEndTime,
} from 'helpers/exclusives/exclusiveListingInfo';
import {
  getHasOngoingOrUpcomingOpenHouses,
  getIsOOMLS,
  getIsSellerOfferExperiment,
  getListingAddressHumanReadable,
  getListingPriceCents,
} from 'helpers/exclusives/listing';

export interface GalleryCardPhotoSectionProps {
  listing: ListingWithComputedProperties;
  showOverlay?: boolean;
  thumbnailUrl: string;
}

export function GalleryCardPhotoSection({
  listing,
  showOverlay = true,
  thumbnailUrl,
}: GalleryCardPhotoSectionProps) {
  const { exclusive_listing_info } = listing;
  const isSellerOfferExperiment = getIsSellerOfferExperiment(listing);
  const isOOMLS = getIsOOMLS(listing);
  const address = getListingAddressHumanReadable(listing);
  const priceCents = getListingPriceCents(listing);
  const price = !!priceCents && `$${(priceCents / 100).toLocaleString()}`;
  const hasOngoingOrUpcomingOpenHouses = getHasOngoingOrUpcomingOpenHouses(listing);
  const earliestOpenHouse = useMemo(
    () => getEarliestOpenHouse(listing.exclusive_listing_info),
    [listing.exclusive_listing_info],
  );
  const daysRemaining = remainingDaysRoundedToEndTime(exclusive_listing_info);
  const daysRemainingText =
    daysRemaining === 0
      ? 'Last day on Exclusives'
      : daysRemaining
      ? `Exclusive for ${daysRemaining} more day${daysRemaining !== 1 ? 's' : ''}`
      : 'Exclusive';
  const isAvailableSoon = listing.el_state === ListingStates.EL_SETUP;
  const overlayText = OVERLAY_STATES[listing.el_state];
  const hasOverlayText = !!overlayText;
  const has3DTour = !!exclusive_listing_info.matterport_id;
  return (
    <Box css={{ borderRadius: 12 }} overflow="hidden" position="relative" zIndex={0}>
      {!isOOMLS && (
        <Flex
          gap={3}
          left={3}
          position="absolute"
          top={3}
          zIndex={1}
          flexWrap="wrap"
          paddingRight="4"
        >
          <>
            {isSellerOfferExperiment && hasOngoingOrUpcomingOpenHouses && !!earliestOpenHouse && (
              <OpenHouseBadge
                endTime={earliestOpenHouse.end_time}
                startTime={earliestOpenHouse.start_time}
              />
            )}
            {isSellerOfferExperiment && isAvailableSoon && <AvailableSoonBadge />}
            {!isSellerOfferExperiment && (!price || isAvailableSoon) ? (
              <AvailableSoonBadge />
            ) : null}
            <ExclusiveBadge text={!isSellerOfferExperiment ? daysRemainingText : undefined} />
            {has3DTour && <VirtualTourBadge />}
          </>
        </Flex>
      )}
      <Image
        alt={`See details about ${address}`}
        as="img"
        aspectRatio="3/2"
        backgroundColor="neutrals30"
        id={`galleryCardImgListingId${listing.id}`}
        loading="lazy"
        objectFit="cover"
        onError={() => {
          const element = document.getElementById(`galleryCardImgListingId${listing.id}`) as
            | HTMLImageElement
            | undefined;

          if (element) {
            element.onerror = null;
            element.src = DEFAULT_LISTING_IMAGE;
          }
        }}
        src={thumbnailUrl}
        transformOrigin="center"
        transitionDuration="250ms"
        transitionProperty="transform"
        transitionTimingFunction="ease-in-out"
        width="100%"
      />
      {showOverlay && hasOverlayText && (
        <Flex
          alignItems="center"
          css={{ backgroundColor: 'rgba(0, 0, 0, 0.56)' }}
          inset={0}
          justifyContent="center"
          position="absolute"
          zIndex="10"
        >
          <Text color="neutrals0" css={{ fontWeight: 500 }} fontSize="s4" lineHeight="s4">
            {overlayText}
          </Text>
        </Flex>
      )}
    </Box>
  );
}

const OVERLAY_STATES: Partial<Record<ListingStates, string>> = {
  [ListingStates.EL_SOLD]: 'Sold',
  [ListingStates.EL_IN_CONTRACT]: 'In Contract',
};
